import {imgUrl} from "../http/baseUrl";
import {post,get} from "../http/http";

//是否关注
export const isCollect = (params) => {
  return post(`${imgUrl}/canfocus`, params, {hideLoading: true});
}

//关注
export const collect = (params) => {
  return post(`${imgUrl}/focus`, params, {hideLoading: true});
}
//关注
export const collectList = (params) => {
  return get(`${imgUrl}/focus/list`, params, {hideLoading: true});
}

//历史记录
export const historyList = (params) => {
  return get(`${imgUrl}/visit/list`, params, {hideLoading: true});
}

//删除历史记录
export const historyDelete = (params) => {
  return get(`${imgUrl}/visit/delete`, params, {hideLoading: true});
}
